/* Grays */

$dark-gray: #505050;
$medium-gray:#8a8a8a;
$light-gray:#bfbfbf;

/* Blues */

$light-blue:#5d84e4;
$light-blue-300: #cad9ff;
$dark-blue: #19315A;
$primary-blue: #002f87;

/* Pinks */

$pink: #ff2d55;
$light-pink: #f17d93;

/* Oranges */

$red:#ff203c;
$famr-red:#c70f46;

$orange:#e7872b;

$purple: #762b85;

$green: #16c784;

$teal:#56B7B4;

/* Settings */

$h4-header: #6f6f6f;
$h4-subtext: #afafaf;

$primary-background-color: white;
$secondary-background-color: #fafbfc;
$primary-border-color:#ecedf1;
$success-color: #4c9e4c;

/* AGL Colors */
$page-header: #292D39;
$page-background: #FFFFFF;
$panel-background: #FBF9FA;
$agl-1: #0A3685;
$agl-2: #6CADD9;
$grid-toolbar: #EFEFEF;
$grid-header: #EBF3FD;
$grid-row-1: #D0E5F5;
$grid-row-2: #FFFFFF;